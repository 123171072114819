import React, {useEffect, useState} from "react";
import { Button,
    Row,
    Card, 
    CardBody, 
    CardHeader, 
    Col, 
    Container, 
    UncontrolledDropdown, 
    DropdownToggle, 
    DropdownMenu, 
    DropdownItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormText,
    Form,
    FormGroup,
    UncontrolledAlert,
    Input,
    Label,} 
from "reactstrap";
import { setUserMachineAPI, removeUserMachineAPI } from "../../api/machine/Machine";
import { setMachinePermission, removeMachinePermission, getMachinePermission, getAllMachinePermission } from "../../api/permission/MachinePermission";
import { GetAllUsers, setUserMachine, removeUserMachine, GetAvailableUser} from "../../api/GetUser"
import { convertDate } from "../../Components/Common/helper";
import Calendar from "react-calendar";
import { isEmptyArray } from "formik";



const Permission = (props) => {
    const {availableMachines, loadDatas, permission, fetchMachinePermission} = props;
    const [selectedOperator, setSelectedOperator] = useState([]);
    const [machine, setMachine] = useState(null);
    const [machineName, setMachineName] = useState(null);
    const [currentMachine, setCurrentMachine] = useState(null);
    const [error, setError] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [errorModal, setErrorModal] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [verifyModal, setVerifyModal] = useState(false);
    const [verifyInfo, setVerifyInfo] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [successVisible, setSuccessVisible] = useState(false);
    const [availableOperators, setAvailableOperators] = useState([]);
    const [calendarModal, setCalendarModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [expireDate, setExpireDate] = useState(null);
    const [dropdownMenu, setDropdownMenu] = useState(false);
    const [operators, setUser] = useState([]);

    const errorToggle = () => {
        setErrorModal(!errorModal);
    };

    const fetchAvailableUsers = async (next=null) => {
        // get all users from backend
        // if next url page exist then get next page data
        const response = await GetAvailableUser(next);
        const nextUrl = response.data.next;
        availableOperators((prev) => [...prev, ...response.data.results]);
        // if we have next url page then get next page data and set it to user state
        if (nextUrl){
            await fetchAvailableUsers(nextUrl)
        };
    };

    const fetchUsers = async (next) => {
        // get all users from backend
        // if next url page exist then get next page data
        const response = await GetAllUsers(next);
        const nextToNext = response.data.next;
        setUser((prev) => [...prev, ...response.data.results]);
        // if we have next url page then get next page data and set it to user state
        if (nextToNext){
            await fetchUsers(nextToNext)
        };
    };

    

    useEffect(() => {
        // when component mounted get all users
        fetchUsers();
    }, []);

    const calendarToggle = (event, machine_user, machine_id, machine_name) => { 
        event.preventDefault();
        if (isEmptyArray(selectedOperator))
        {
            alert(props.t('noOperatorWarning'))
        }
        else {
            setMachine(machine_id);
            setMachineName(machine_name);
            setCalendarModal(!calendarModal);
        }
    };

    const calendarClose = () => {
        setSelectedOperator([]);
        setMachine(null);
        setCalendarModal(false);
    };

    const verifyToggle = (e, machine, info) => {
        e.preventDefault(); 
        setCurrentMachine(machine);
        setVerifyInfo(info);
        setVerifyModal(!verifyModal); 
    };
        
    const successToggle = () => { setSuccessVisible(!successVisible) };


    useEffect(() => {
        setAvailableOperators(operators.filter(operator => operator.machine_id === null));
    }, [operators]);

    const checkSelectedOperator = (machine_name) => {
        try {
            if (isEmptyArray(selectedOperator)) {
                /*
                    * if selected operator is an empty list,
                    * just return false, there is no problem
                */
                return false;
            }
            else {
                if ( machine_name == machine.name){ 
                    /*
                        * if selected machine name equal to before selected machine name
                        * there is no problem, return false
                    */ 
                    return false;
                }
                else {
                    /*
                        * if selected machine name is not equal to previous machine name,
                        * its mean, user choose new machine but we alread has not approved
                        * operator list, so give them user a warning message
                    */
                    return true;
                };
            }
        }
        catch (e) {
            // console.log(e);
        }
    };

    const handleClick = async(e, machineName, machine_id, machine_user, operator_id, operator_name) => {
        /* 
            * dropdown users click event
        */ 
        const machine_name = machineName.replace(/\s/g, '');
        if (!checkSelectedOperator(machine_name)){
            const toggle = e.target.checked;
            const instance = selectedOperator;
            if (toggle) {
                // ! user want to add new operator 
                if (!isEmptyArray(instance)) {
                    /*
                        * if selected operator is not empty array 
                        * get previous operator data from state
                        * if new operator is not exists previous data,
                        * add new operator data to state
                        * finally update state
                    */
                    if (!instance.some(user => user.id === operator_id)) {   // check operator is exists
                        instance.push({ 
                            id: operator_id,
                            username: operator_name,
                        });          // push new operator to array
                        setSelectedOperator(instance);         // set new operator array to state

                        setMachine({
                            id: machine_id,
                            name: machine_name
                        })
                    };  
                }
                else {
                    if (!isEmptyArray(machine_user)) {
                        /*
                            * if seleceted operator is empty
                            * get all machine user's username already has,
                            * then push these usernames to users array
                            * finally push new added operator name to users array
                        */
                        const users = []
                        machine_user.map((user) => {
                            // const op = operators.filter((operator) => operator.id === user)[0];
                            users.push({
                                id: user.id,
                                username: user.username,
                            });
                        });
                        users.push({ 
                            id: operator_id,
                            username: operator_name,
                        });  // push new user to array.

                        //* update selected operator state
                        setSelectedOperator(users);

                        //* update machine state 
                        setMachine({
                            id: machine_id,
                            name: machine_name
                        })
                    }
                    else {
                        //! there is no machine user already set
                        if (!isEmptyArray(selectedOperator)) {
                            /*
                                * if selected operator is not a empyt list 
                                * get previous data and add new user data to them
                                * finally update state
                            */
                            instance.push({ 
                                id: operator_id,
                                username: operator_name,
                            })            // push new user to list
                            setSelectedOperator(instance);          // update state
                            setMachine({
                                id: machine_id,
                                name: machine_name
                            })
                        }
                        else {
                            /*
                                * if selected operator state is empty
                                * just add new username and update state 
                            */
                            instance.push({ 
                                id: operator_id,
                                username: operator_name,
                            });
                            setSelectedOperator(instance);               // update state
                            setMachine({
                                id: machine_id,
                                name: machine_name
                            })
                        }
                    }
                }    
            }
            else {
                /*
                    * checkbox is not checked, so user want to remove operator permission from machine
                    * if selected operator state is not empty,
                    * remove selected operator from state
                */
                if (!isEmptyArray(instance)) {                                      // check state
                    if (instance.some(user => user.id === operator_id)) {                    // operator is exists 
                        // instance.splice(instance.indexOf(operator_name), 1);  // find operator name and remove from list
                        // setSelectedOperator(instance);                         // update state
                        // Find the index of the element to remove
                        const indexToRemove = instance.findIndex(user => user.id === operator_id);
                        
                        // Remove the element from the array
                        if (indexToRemove !== -1) { // Check if the element exists
                            instance.splice(indexToRemove, 1); // Remove 1 element starting from indexToRemove
                        }

                        // Update states
                        setSelectedOperator([...instance]); // Update the state with a new array to trigger re-rendering
                        setMachine({
                            id: machine_id,
                            name: machine_name
                        });
                    };
                };
            };
        }
        else {
            // eğer selected operator state'i boş liste değilse
            // uyarı ver 
            setErrorMsg(props.t('approvePrevChooseMsg'));
            setError(true);
            errorToggle();
        };
    };

    const handleDelete = async (e, machine_id) => {
        e.preventDefault();
        // clear error and success messages
        setError(false);
        setErrorMsg(null);
        setErrorModal(false);
        setSuccessMsg(false);
        setSuccessVisible(false);
        // close verify modal
        setVerifyModal(false);
        setVerifyInfo(null);

        // get user information from selectedOperator
        // set user machine
        const response = await removeMachinePermission(machine_id);
        if (response.request.status === 204) {
            // if machine's permissions removed successfuly backend will return 204 status.
            // if process is okey call permission function again to show new condition.  
            // end return user success notification
            fetchMachinePermission();  // call permission function
            loadDatas();
            setSuccessMsg(props.t('removePermissionSuccess'));
            setSuccessVisible(true);
            setTimeout(() => {
                setSuccessVisible(false);
                setSuccessMsg(false);
            }, 3000)
        }
    };

    const handleStartDate = (date) => {
        // convert date to timezone for backend
        // and set to state
        setStartDate({
            datetime: convertDate(date),
            timezone: date.toISOString()
        });
    };

    const handleExpireDate = (date) => {
        // convert date to timezone for backend
        // and set to state
        setExpireDate({
            datetime: convertDate(date),
            timezone: date.toISOString()
        });
    };

    const handleDateAccept = async () => {
        // first check date selected or not
        try {
            if (selectedOperator.length === 0) {
                alert(props.t('noOperatorWarning'));
                return;
            }
            else {
                const startedAt = startDate ? startDate.timezone : null;
                const expiredAt = expireDate ? expireDate.timezone : null;
                for (const user of selectedOperator) {
                    const response = await setMachinePermission(user.id, machine, startedAt, expiredAt);
                }


                // wait 100 ms and new machine permissions
                setTimeout(() => {
                    fetchMachinePermission();
                    loadDatas();
                }, 100);

                setSuccessMsg(props.t('createPermissionSuccess'));
                successToggle();
                setTimeout(() => {
                    setSuccessMsg(false);
                    setSuccessVisible(false);
                }, 5000)
            };
        }
        catch (e) {
            // console.log(e);
        };
        calendarClose();
    };

    const oneDayLater = () => {
        const today = new Date();
        today.setDate(today.getDate() + 1);
        return new Date(today)
    };

    function GlobalFilter({
        globalFilter,
        setGlobalFilter,
        SearchPlaceholder,
      }) {
        return (
          <React.Fragment>
            <CardBody>
              <form>
                <Row className="g-3">
                  <Col>
                    <div className="search-box me-2 mb-2 d-inline-block col-12" style={{border: '1px solid #80808047', borderRadius: '3px'}}>
                      <input
                        // onChange={(e) => {
                        //   setValue(e.target.value);
                        //   onChange(e.target.value);
                        // }}
                        id="search-bar-1"
                        type="text"
                        className="form-control search /"
                        placeholder={SearchPlaceholder}
                        // value={value || ""}
                      />
                      <i className="bx bx-search-alt search-icon"></i>
                    </div>
                  </Col>
                </Row>
              </form>
            </CardBody>
      
          </React.Fragment>
        );
      }

    return ( 

        <Container fluid>
            <UncontrolledAlert 
                color="secondary" 
                isOpen={successMsg} 
                toggle={successToggle} 
                fade={true} 
                className="text-center"
                
                >
                {successMsg && <strong>{successMsg}</strong>}
            </UncontrolledAlert>

            <UncontrolledAlert 
                color="danger" 
                isOpen={errorMsg} 
                toggle={errorToggle} 
                fade={true} 
                className="text-center">
                {errorMsg && <strong>{errorMsg}</strong>}
            </UncontrolledAlert>

            <Modal isOpen={calendarModal} toggle={calendarToggle} centered={true} size="lg" onClosed={calendarClose}>
                <ModalHeader toggle={calendarToggle} >
                    <span className="fw-bold text-uppercase me-2">{machineName && machineName}</span> 
                    <span className="text-muted">{props.t('permission')}</span>
                </ModalHeader>
                <ModalBody>
                    <Row className="mt-3">
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <h6 className="text-muted">{props.t('startDate')}</h6>
                            <Calendar onChange={handleStartDate} minDate={new Date()}/>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <h6 className="text-muted">{props.t('endDate')}</h6>
                            <Calendar onChange={handleExpireDate} minDate={oneDayLater()}/>
                        </Col>

                        <div className="d-flex align-items-center gap-4 mt-3 ms-2">
                                <div className="d-flex gap-2 align-items-center">
                                    <i className='bx bxs-square-rounded fs-6' style={{color:'#006edc'}}></i>
                                    <span>{props.t('today')}</span>
                                </div>
                                <div className="d-flex gap-2 align-items-center">
                                    <i className='bx bxs-square-rounded fs-6' style={{color: '#ca0c0cc4'}}></i>
                                    <span>{props.t('choiceDate')}</span>
                                </div>
                        </div>
                    </Row>
                    <Row>
                        <Col xs={6} sm={6} md={6} lg={12} className="mt-5">
                            <div className='content' >

                                { !isEmptyArray(selectedOperator) && selectedOperator.map((item) => {
                                        return (
                                            <p className="text-center align-items-center">
                                                <i className='bx bxs-user me-2 text-primary'></i>
                                                <strong>{item.username}</strong>
                                            </p>
                                        )
                                    })
                                } 
                                <p className="text-center">
                                    <strong>{startDate && startDate != null ? startDate.datetime : convertDate(new Date())}</strong>
                                    <i className='bx bxs-chevrons-right ms-3 text-warning'></i>
                                    <i className='bx bxs-chevrons-right me-3 text-warning'></i>
                                    <strong>{expireDate && expireDate != null ? expireDate.datetime : props.t('chooseDate')}</strong>
                                </p>
                                <p className="fs-12 align-items-center  mb-0">
                                    <i className='bx bxs-error-circle me-2 text-danger'></i>
                                    <span id='day-alert' className="text-muted">{props.t('warning1')}</span>
                                </p>
                                <p className="fs-12 align-items-center  mt-0">
                                    <i className='bx bxs-error-circle me-2 text-danger'></i>
                                    <span className="text-muted">{props.t('warning2')}
                                        <span className="ms-1 text-danger">{props.t('soon')}</span>
                                    </span>
                                </p>
                                <Button color="success"  className="w-100" onClick={handleDateAccept}>{props.t('approve')}</Button>

                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

            <Modal isOpen={verifyModal} toggle={verifyToggle} centered={true}>
                <ModalHeader toggle={verifyToggle} className="">
                    <h5>{ verifyInfo && verifyInfo.header } <span className="text-muted">| {props.t('removePermission')}</span> </h5>
                </ModalHeader>
                <ModalBody className="p-4 mt-2">
                    <p className="fs-6">{ verifyInfo && verifyInfo.message }</p>
                    <Button color='outline-danger' className="float-end w-25"
                        onClick={(e) => handleDelete(e, currentMachine)}>
                        {props.t('approve')}
                    </Button>
                </ModalBody>
            </Modal>

            <Card style={{ border: '1px solid #e9ebec', borderRadius: '5px', boxShadow: '0 3px 5px rgba(56,65,74,0.15)' }}>
                <CardHeader style={{borderBottom:'1 px solid gray'}}>
                    <h1 className="card-title text-center">{props.t('machinePermission')}</h1>
                </CardHeader>

                <GlobalFilter SearchPlaceholder={props.t('machinePermissionPlaceHolder')} />
                <CardBody style={{ maxHeight: '430px', overflow: 'auto'}}>
                        { permission && availableMachines && availableMachines.map((item) => {
                            return (
                                <div className="d-flex p-3 align-items-center  text-center" style={{border:'1px solid #d5d5d54d'}}>
                                    <Col xs={4} sm={4} md={4} lg={4}>
                                        <Button className="btn-soft-info w-80">{item.machine.name}</Button>
                                    </Col>
                                    <Col xs={5} sm={5} md={5} lg={5}>
                                        <UncontrolledDropdown>
                                            <DropdownToggle caret 
                                                color={
                                                    permission && permission.map((perm) => perm.machine === item.machine.id).includes(true) ? "success" :
                                                    machine && machine.name === item.machine.name  ? "success" : "dark" 
                                                }
                                                className="w-100">
                                                {
                                                    permission && permission.map((perm) => perm.machine === item.machine.id).includes(true) ? props.t('activeChoose'):
                                                    machine && machine.name === item.machine.name ? props.t('justChoose'):
                                                    props.t('noChoose')
                                                }
                                            </DropdownToggle>
                                            <DropdownMenu dark className="mt-1">
                                                <div className="form-check p-2" >
                                                {!isEmptyArray(operators) && operators.map((op) => (
                                                    <div>
                                                        <Input className="form-check-input ms-0 me-2" 
                                                                type="checkbox" 
                                                                id={op.id} style={permission && permission.map((perm) => item.machine.id === perm.machine && perm.user.id).includes(op.id) ? 
                                                                    {   border:'1px solid rgb(151, 81, 218)', 
                                                                        backgroundColor: 'rgba(181, 119, 240, 0.99)'
                                                                    } : 
                                                                        {border:'1px solid #8d8d8dd4'}}
                                                                onClick={(e) => handleClick(
                                                                    e, item.machine.name, item.machine.id, 
                                                                    permission && permission.filter((perm) => perm.machine === item.machine.id && perm.user)
                                                                                            .map((item) => item.user), 
                                                                    op.id, op.username
                                                                )}
                                                               />
                                                        <Label className="form-check-label" for={item.id}>
                                                            {op ? op.username : props.t('noAvailable') }
                                                        </Label>
                                                    </div>
                                                )) }
                                            </div> 
                                                    
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </Col>

                                    {/* Kullanıcı izin ve izin kaldırma butonları */}
                                    <Col xs={3} sm={3} md={3} lg={3}>
                                        <div className="d-flex gap-1 justify-content-center" >

                                            {/* <Button className="btn btn-soft-success w-25 p-1" onClick={(e) => handleSave(e, machine.id)}>
                                                <i className='bx bx-check fs-4'></i>
                                            </Button> */}
                                            <Button className="btn btn-soft-success w-90 p-1" onClick={(e) => calendarToggle(e, item.user, item.machine.id, item.machine.name)} >
                                                <i className='bx bx-check fs-4'></i>
                                            </Button>
                                            <Button className="btn btn-soft-danger w-90 p-1" 
                                                onClick={(e) => verifyToggle(
                                                        e, 
                                                        item.machine.id, 
                                                        {
                                                            header: `${item.machine.name}`,
                                                            message: props.t('removePermissionQuestion')
                                                        })
                                                        }>
                                                <i className='bx bx-x fs-4'></i>
                                            </Button>
                                        </div>
                                    </Col>
                                </div>
                            )
                            }
                        )}
                </CardBody>
            </Card>
        </Container>
    );
};

export default Permission;
