import { createSlice } from '@reduxjs/toolkit';

const layoutTypes = {
    VERTICAL: "vertical",
    SEMIBOX:"semibox"
  };
  
  const layoutWidthTypes = {
    FLUID: "lg",
    BOXED: "boxed",
  };
  
  const layoutPositionTypes = {
    FIXED: "fixed",
    SCROLLABLE: "scrollable",
  };
  
  const leftsidbarSizeTypes = {
    DEFAULT: "lg",
    COMPACT: "md",
    SMALLICON: "sm",
    SMALLHOVER: "sm-hover"
  };
  
  const leftSidebarViewTypes = {
    DEFAULT: "default",
    DETACHED : "detached"
  };
  
  const preloaderTypes = {
    ENABLE: "enable",
    DISABLE: "disable"
  }
  
  const sidebarVisibilitytypes = {
    SHOW: "show",
    HIDDEN: "hidden"
  }

const initialState = {
  layoutType: layoutTypes.VERTICAL,
  layoutWidthType: layoutWidthTypes.FLUID,
  layoutPositionType: layoutPositionTypes.FIXED,
  leftsidbarSizeType: leftsidbarSizeTypes.DEFAULT,
  leftSidebarViewType: leftSidebarViewTypes.DEFAULT,
  preloader: preloaderTypes.DISABLE,
  sidebarVisibilitytype: sidebarVisibilitytypes.SHOW
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
      changeLayoutAction(state, action) {
        state.layoutType = action.payload;
      },
      changePreLoaderAction(state, action) {
        state.preloader = action.payload;
      },
      changeSidebarVisibilityAction(state, action) {
        state.sidebarVisibilitytype = action.payload;
      }
  },
});

/**
 * Changes the layout type
 * @param {*} param0
 */
export const changeLayout = (layout) => async (dispatch) => {
    try {
        if (layout === "twocolumn") {
            document.documentElement.removeAttribute("data-layout-width");
        } else if (layout === "horizontal") {
            document.documentElement.removeAttribute("data-sidebar-size");
        } else if (layout === "semibox") {
            changeHTMLAttribute("data-layout-width", "fluid");
            changeHTMLAttribute("data-layout-style", "default");
        }
        changeHTMLAttribute("data-layout", layout);
        dispatch(changeLayoutAction(layout));
    } catch (error) { }
};

/**
 * Changes the Preloader
 * @param {*} param0
 */
export const changePreLoader = (preloaderTypes) => async (dispatch) => {
    try {
        changeHTMLAttribute("data-preloader", preloaderTypes);
        dispatch(changePreLoaderAction(preloaderTypes));
    } catch (error) {
        // console.log(error);
    }
};

/**
 * Changes the sidebar visibility
 * @param {*} param0
 */
export const changeSidebarVisibility = (sidebarVisibilitytype) => async (dispatch) => {
    try {
        changeHTMLAttribute("data-sidebar-visibility", sidebarVisibilitytype);
        dispatch(changeSidebarVisibilityAction(sidebarVisibilitytype));
    } catch (error) { }
};

const changeHTMLAttribute = (attribute, value) => {
    if (document.documentElement) document.documentElement.setAttribute(attribute, value);
    return true;
}

// Action'ları ve reducer'ı export et
export const { changeLayoutAction,
    changePreLoaderAction,
    changeSidebarVisibilityAction } = sidebarSlice.actions;
export default sidebarSlice.reducer;