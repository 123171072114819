import React, { useEffect, useState } from 'react';
import { ReportTypeDropdown } from '../../api/report/Report';
import Select from 'react-select';

const ReportTypeFilter = (props) => {
    // Filter report types 
    const [options, setOptions] = useState([]);
  
    const fetchOptions = async () => {
        // Fetch Flaw Types from API
        const response = await ReportTypeDropdown();
        const data = response.result.map((item) => ({
            value: item[0],
            label: item[1]
        }));
        setOptions(data);
    }
  
    useEffect(() => {
      fetchOptions();
    }, []);
  
    
    return (
        <Select options={options}
                placeholder={props.t('reportTypePlaceHolder')}
                noOptionsMessage={() => props.t('noResult')}
                loadingMessage={() => props.t('loading')}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? 'grey' : 'dodgerblue',
                  }),
                  menu: base => ({
                    ...base,
                    background: 'white',
                    zIndex: 2
                  })
                }}
                onChange={(e) => props.setReportType(e)}
                />
    )
  }
  
  export {ReportTypeFilter};