import React, { useEffect, useMemo, useState } from 'react';
import TableContainer from '../../Components/Common/TableContainerReactTable';
import { Button, Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { GetAllUsers, removeUserMachine } from '../../api/GetUser';
import { removeUserMachineAPI } from '../../api/machine/Machine';
import { isEmptyArray } from 'formik';
import { convertDate as dateConverter } from '../../Components/Common/helper';
import { getMachinePermission } from '../../api/permission/MachinePermission';




const AdminTable = (props) => {
    const { availableMachines, operators, permission } = props;
    const [user, setUser] = useState([]);
    const [machinePermission, setMachinePermission] = useState(null);
    const [machineDetail, setMachineDetail] = useState(null);
    const [toggle, setToggle] = useState(false);


    const handleModal = () => {
        setToggle(!toggle);
    };

    const fetchUsers = async (next) => {
        const response = await GetAllUsers(next);
        const nextToNext = response.data.next;
        setUser((prev) => [...prev, ...response.data.results]);
        // if we have next url page then get next page data and set it to user state
        if (nextToNext){
            await fetchUsers(nextToNext)
        }
    }

    useEffect(() => {
        try{
            GetAllUsers().then(async (response) => {
                setUser(response.data.results);
                const next = response.data.next;
                // if we have next url page then get next page data and set it to user state
                if (next){
                    await fetchUsers(next);
                }
            });
        }
        catch (error){
            // console.log(error);
        }
    }, []);

    const expirePermission = async (machine, user) => {
        /* delete user permission if time is finished */
        await removeUserMachineAPI(machine);
        for (const u of user) {
            await removeUserMachine(u);
        }
    };

    const convertPyhtonDate = (item) => {
        /* convert python date to js date */
        if (item){
            const dateTimeList = [];
            item.forEach((timezone) => {
                const date = dateConverter(timezone);  // use helper function of date converter
                dateTimeList.push(date);
            });
            return dateTimeList;
        };
    };

    const convertDay = (item, machine, user) => {
        if (item !== null || !isEmptyArray(item)) {
            const dayList = [];
            item.forEach(element => {
               
                const today = new Date();
                
                // get permission day 
                const permissionDay = new Date(element).getDate();
                // get permission month
                const permissionMonth = new Date(element).getMonth();
                // get permission year
                const permissionYear = new Date(element).getFullYear();
                
                // get today day
                const todayDay = today.getDate();
                // get today month
                const todayMonth = today.getMonth();
                // get today year
                const todayYear = today.getFullYear();
                
                // get permission date
                const permissionDate = new Date(permissionYear, permissionMonth, permissionDay);
                // get today date
                const todayDate = new Date(todayYear, todayMonth, todayDay);
                
                // get difference between permission date and today date
                const difference = permissionDate - todayDate;
                // get difference day
                const differenceDay = Math.floor(difference / (1000 * 60 * 60 * 24));
                
                // if difference day is less than 0 then permission is expired
                
                // if (differenceDay <= 0){
                //     expirePermission(machine, user);
                // }

                dayList.push(differenceDay);  // push alloowed day of user to result array
                // return differenceDay;
            });
            return dayList;  // return permissioned day array
        }
        else {
            return null;
        }
    };


    const handleUserField = (userList) => {
        /* 
            * Get user list which has perrmission on machine
            * If we have more than one user, just return first
            * user and add `...` end of the user.
            * If we have just an user, just return that.
        */
        if (!isEmptyArray(userList)) {
            const username = userList.length > 1 ? `${userList[0].user.username}...` : userList[0].user.username;
            return username
        }
    }

    const handlePermissionDay = (permissionDate) => {
        /*
            * Get user list from backend and show the permissioned day
            * If machine have more than one user, just return first,
            * expired day and add `...` end of the user.
            * If machine has only one user, just return that user expired day
        */
        if (!isEmptyArray(permissionDate)) {
            const dayList = convertDay(permissionDate);  // get permission day list
            const expired_day = dayList.length > 1 ? `${dayList[0]}...` : dayList[0]
            return expired_day;
        };
    };

    const handleDateTime = (permissionDate) => {
         /*
            * Get user list from backend and show the permissioned day
            * If machine have more than one user, just return first,
            * expired datetime and add `...` end of the user.
            * If machine has only one user, just return that user's expired datetime
        */
       if (!isEmptyArray(permissionDate)) {
            const dateTimeList = convertPyhtonDate(permissionDate);  // get expired datetime list
            const expiredDateTime = dateTimeList.length > 1 ? `${dateTimeList[0]}...` : dateTimeList[0]
            return expiredDateTime;
       };
    };


    const handlePermissionDetail = async (event, machineId, machineName) => {
        /* Show machine permission detail */
        event.preventDefault();
        if (machineId) {
            const response = await getMachinePermission(machineId);  // get machine permissions
            if (response.request.status === 200) {
                // if reponse is OK and result of response  is not empty array set to state
                setMachinePermission(response.data.results)  // set to state
                setMachineDetail({
                    "id": machineId,
                    "name": machineName
                });  // set machine name to state for modal
                handleModal();  // open modal
            }
            else {
                alert(props.t('serverError'))
            }
        };

    }

    const searchTable = availableMachines.map((item) => {
        // get users who has permission on machine item
        const machineUser = permission && permission
                .filter((perm) => perm.machine == item.machine.id)
        // get users' expired date who has permission on machine item
        const endedAt = permission && permission
            .filter(perm => perm.machine === item.machine.id && perm.expired_at)
            .map(perm => perm.expired_at)
        const machines = {
            "machine_id": item.id,
            "machine_name": item.machine.name,
            "machine_authority": handleUserField(machineUser),
            "machine_order": item.order,
            "machine_roll": item.roll,
            "permission_date": handlePermissionDay(endedAt),
            "real_date": handleDateTime(endedAt)
        }
        return machines;
    });

    const columns = useMemo(
        () => [
            // {
            //     Header: 'Makina ID',
            //     accessor: 'machine_id',
            // },
            {
                Header: props.t('machineName'),
                accessor: (cellProps) => {
                    return (
                        <button className='infobox btn btn-sm btn-outline-dark'
                                onClick={(e) => {handlePermissionDetail(e, cellProps.machine_id, cellProps.machine_name)} }>
                            <b>
                                {cellProps.machine_name}
                            </b>
                            <span className='infobox-content'>
                               {props.t('clickDetails')}.
                            </span>
                        </button>
                    )
                },
            },
            {
                Header: props.t('machineAuth'),
                accessor: 'machine_authority'
            },
            {
                Header: props.t('authDay'),
                accessor: 'permission_date',
            },
            {
                Header:  props.t('authDate'),
                accessor: 'real_date',
            }

        ],
        [props.t]
    );


    return (
            <Container fluid>
                <Modal isOpen={toggle} toggle={handleModal} centered={true} size='lg'>
                    <ModalHeader toggle={handleModal}>
                        { machineDetail && `${machineDetail.name}`}
                    </ModalHeader>
                    <ModalBody>
                        <div className='modal-content p-5' style={{maxHeight: '400px', overflow: 'auto'}}>
                            {
                                machinePermission ? machinePermission.map((item) => {
                                    return (
                                        <Row className='mb-3 p-4' 
                                            style={{border: '1px solid #8080805c', borderRadius: '3px' }}> 
                                            <Col md={6} lg={6} sm={6}> 
                                            <div className='user-info gap-2 d-flex align-items-center mb-2'>
                                                <i className='bx bxs-user text-secondary fs-4'></i>
                                                <span> <b>{ item.user.username }</b></span>
                                            </div>
                                            <div className='started-at gap-2 d-flex align-items-center mb-2'>
                                                <i className='bx bxs-calendar text-success fs-4'></i>
                                                <span> <b>{ item.started_at && convertPyhtonDate([item.started_at])}</b> </span>
                                            </div>
                                            <div className='expired-at gap-2 d-flex align-items-center'>
                                                <i className='bx bxs-calendar text-danger fs-4'></i>
                                                <span> <b>{ item.expired_at && convertPyhtonDate([item.expired_at])}</b> </span>
                                            </div>
                                            </Col>
                                            <Col md={6} lg={6} sm={6}>
                                                <Button className='w-100 mb-2' color='outline-danger'>
                                                    {props.t('removePermission')}
                                                </Button>
                                                <Button className='w-100' color='outline-success'>
                                                    {props.t('renewPermission')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    )   
                                }) : ""
                            }
                        </div>
                    </ModalBody>
                </Modal>

                <Card style={{ border: '1px solid #e9ebec', borderRadius: '5px', boxShadow: '0 3px 5px rgba(56,65,74,0.15)' }}>
                    <CardHeader>
                        <h5 className='card-title text-center mb-0'>{props.t('machinePermissionTable')}</h5>
                    </CardHeader>
                    <CardBody>
                        <TableContainer
                            columns={(columns || [])}
                            data={(searchTable.reverse() || [])}
                            isPagination={true}
                            flaw={true} // its just for web socket. After change flaw variable as websocket
                            isGlobalFilter={true}
                            iscustomPageSize={false}
                            isBordered={false}
                            customPageSize={5}
                            className="custom-header-css table align-middle table-nowrap"
                            tableClassName="table-centered align-middle table-nowrap mb-0"
                            theadClassName="text-muted table-light"
                            SearchPlaceholder={props.t('searchMachine')}
                            />
                    </CardBody>
                </Card>
            </ Container>
    );

};

export default AdminTable;
