import axios from "axios";
import { DecryptToken } from "../../api/helpers/decrypt";

const token = DecryptToken();


const getFlawDetails = async (id) => {
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/flaw/${id}/`,
            headers: {
                'Authorization': token
            }
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    }
    );

    try{
        const response = await api;
        return response;
    }
    catch (error){
        return error;
    }
};


const FlawTypeDropdown = async () => {
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/flaw/dropdown/`,
            headers: {
                'Authorization': token
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    }
    );

    try{
        const response = await api;
        return response;
    }
    catch (error){
        return error;
    }
};

const removeFlaw = async (flaw_id) => {
    /*
        * Soft delete for given flaw
    */
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}/flaw/${flaw_id}/`,
            headers: {
                'Authorization': token
            }
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    }
    );

    try{
        const response = await api;
        return response;
    }
    catch (error){
        return error;
    }
}

const restoreFlaw = async (flaw_id, notification_id) => {
    /*
        * Restore deleted flaw
    */
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'patch',
            url: `${process.env.REACT_APP_API_URL}/flaw/restore/${flaw_id}/`,
            headers: {
                'Authorization': token
            },
            data: {
                deleted_at: null,
                notification_id: notification_id
            }
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    }
    );

    try{
        const response = await api;
        return response;
    }
    catch (error){
        return error;
    };
};

const markAsReadToFlaw = async (notification_id) => {
    /*
      * Mark as read given notification
    */
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'delete',
            url:  `${process.env.REACT_APP_API_URL}/notification/${notification_id}/`,
            headers: {
                'Authorization': token
            }
        })
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        });
    });

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    };
}

const getFilteredFlaw = async (filters, customUrl) => {
    const filteredParams = (fParams) => {
        return  Object.fromEntries(
         Object.entries(fParams).filter(([key, value]) => value !== null)
        );
    }

    // filter flaws according to given filters
    function jsonToQueryString(json) {
        return Object.keys(json)
            .map(key => json[key] && `${key}=${json[key]}`)
            .join('&');
    }

    let params = filters && filteredParams(filters);
    params =  params && jsonToQueryString(params);


    const url = customUrl ? customUrl : `${process.env.REACT_APP_API_URL}/flaw/?${params}`

    const api = new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url:  url,
            headers: {
                'Authorization': token
            }
        })
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        });
    });

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    };
};

const getFilteredFlawWithPartyNo = async (filters, customUrl) => {
    const filteredParams = (fParams) => {
        return  Object.fromEntries(
         Object.entries(fParams).filter(([key, value]) => value !== null)
        );
    }

    // filter flaws according to given filters
    function jsonToQueryString(json) {
        return Object.keys(json)
            .map(key => json[key] && `${key}=${json[key]}`)
            .join('&');
    }

    let params = filters && filteredParams(filters);
    params =  params && jsonToQueryString(params);


    const url = customUrl ? customUrl : `${process.env.REACT_APP_API_URL}/flaw/partyno/?${params}`

    const api = new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url:  url,
            headers: {
                'Authorization': token
            }
        })
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        });
    });

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    };
};

export {FlawTypeDropdown, getFlawDetails, removeFlaw, restoreFlaw, markAsReadToFlaw, getFilteredFlaw, getFilteredFlawWithPartyNo};
