import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { DecryptUserInfo } from '../../api/helpers/decrypt';
import AOS from 'aos';

const SidebarContent = () => {

  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  const { t } = useTranslation('navbar');
  const [user, setUser] = useState([]);
  const [error, setError] = useState([]);

  useEffect(() => {
    AOS.init();
    try {
        setUser(DecryptUserInfo());
    }
    catch (err) {
        setError(err);
    }
}, [iscurrentState]);


  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: t('home'),
      icon: "bx bx-home me-2",
      link: "/dashboard",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Dashboard");
      },
    },
    {
      id: "machine",
      label: t('machineList'),
      icon: "bx bx-server me-2",
      link: "/machine",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Machine");
      },
    },
    {
      id: "order",
      label: t('orderList'),
      icon: "bx bx-server me-2",
      link: "/order",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Order");
      },
    },
    {
      id: "report",
      label: t('reportPage'),
      icon: "bx bx-server me-2",
      link: "/report",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Report");
      },
    },
    {
      id: "settings",
      label: t('settings'),
      icon: "bx bx-cog me-2",
      link: "/settings",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Settings");
      },
      condition: user.status === 'admin' || user.status === 'superadmin',
    },
  ];
  const filteredMenuItems = menuItems.filter(item => item.condition === undefined || item.condition);

  return <React.Fragment>{filteredMenuItems}</React.Fragment>;
};
export default SidebarContent;
