import React, { useEffect, useState } from 'react';

import Header from './Header';
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';
import Sidebar from './sidebar/Sidebar';
import {
    changeLayout,
    changeSidebarVisibility
} from "../Components/Hooks/slices/sidebarSlice";


const Layout = ({children}) => {

    const dispatch = useDispatch();
    const selectLayoutState = (state) => state.sidebar;
    const selectLayoutProperties = createSelector(
        selectLayoutState,
        (layout) => ({
            layoutType: layout.layoutType,
            preloader: layout.preloader,
            sidebarVisibilitytype: layout.sidebarVisibilitytype,
        })
    );
    
    // Inside your component
    const {
        layoutType,
        sidebarVisibilitytype
    } = useSelector(selectLayoutProperties);

    /*
    layout settings
    */
    useEffect(() => {
        if (
            layoutType ||
            sidebarVisibilitytype
        ) {
            window.dispatchEvent(new Event('resize'));
            dispatch(changeLayout(layoutType));
            dispatch(changeSidebarVisibility(sidebarVisibilitytype));
        }
    }, [layoutType,
        sidebarVisibilitytype,
        dispatch]);

    useEffect(() => {
        if (sidebarVisibilitytype === 'show' || layoutType === "vertical" || layoutType === "twocolumn") {
            document.querySelector(".hamburger-icon").classList.remove('open');
        } else {
            document.querySelector(".hamburger-icon") && document.querySelector(".hamburger-icon").classList.add('open');
        }
    }, [sidebarVisibilitytype, layoutType]);


    return (
        <React.Fragment>
            <Header />
            <Sidebar layoutType={layoutType}/>
            <main>
                {children}
            </main>
        </React.Fragment>

    );
};

export default Layout;