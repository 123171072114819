import { Dropdown, DropdownMenu, DropdownToggle, Form, Modal, ModalBody, ModalHeader, ModalFooter, Button} from 'reactstrap';
import { ProfileDropdown} from '../Components/Common/ProfileDropdown';
import { LanguageDropdown} from '../Components/Common/LanguageDropdown';
import { DecryptUserInfo } from '../api/helpers/decrypt';
import Notification from '../Components/Common/Notification';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import 'aos/dist/aos.css';
import AOS from 'aos';
import {changeSidebarVisibility} from "../Components/Hooks/slices/sidebarSlice";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';
import { useTranslation } from 'react-i18next';


const Header = () => {
    const [search, setSearch] = useState(false);
    const [active, setActive] = useState('');
    const [modal, setModal] = useState(false);
    const [user, setUser] = useState([]);
    const [error, setError] = useState([]);
    const { t } = useTranslation('navbar');

    const location = useLocation();
    const dispatch = useDispatch();


    const selectDashboardData = createSelector(
        (state) => state.sidebar,
        (state) => ({
            sidebarVisibilitytype: state.sidebarVisibilitytype})
      );
    const {sidebarVisibilitytype} = useSelector(selectDashboardData);


    const toogleSearch = () => {
        setSearch(!search);
    };

    const logoutToggle = () => {
        setModal(!modal);
    };

    const toogleMenuBtn = () => {
        var windowSize = document.documentElement.clientWidth;
        dispatch(changeSidebarVisibility("show"));

        if (windowSize < 795)
            document.querySelector(".hamburger-icon").classList.toggle('open');

        //For collapse vertical and semibox menu
        if (sidebarVisibilitytype === "show" && (document.documentElement.getAttribute('data-layout') === "vertical" || document.documentElement.getAttribute('data-layout') === "semibox")) {
            if (windowSize <= 795) {
                document.body.classList.add('vertical-sidebar-enable');
                document.documentElement.setAttribute('data-sidebar-size', 'lg');
            }
        }
    };

    const handleLogout = () => {
        Cookies.remove('token');
        Cookies.remove('info');
        setTimeout(() => {
            window.location.href = '/auth/login';
        }, 1000);
    };


    useEffect(() => {
        AOS.init();
        try {
            setUser(DecryptUserInfo());
        }
        catch (err) {
            setError(err);
        }
    }, [active]);

    const activeLink = (event) => {
        const box = event.target.parentElement.parentElement.parentElement;
        if (!box.classList.contains('active-box') && box.classList.contains('box')){
            const previous = document.getElementsByClassName('active-box')[0];
            if (previous) {
                previous.classList.remove('active-box');
            }
            box.classList.add('active-box');
        }
    };

    useEffect(() => {
        const path = location.pathname.split('/')[1];
        setActive(path);
    }, [location]);



    return (
        <React.Fragment>
            {/* LOGOUT MODAL */}
            <Modal isOpen={modal} toggle={logoutToggle} centered={true}>
                <ModalHeader toggle={logoutToggle}>{t('logout')}</ModalHeader>
                <ModalBody>
                    <p>{t('areYouSure')}</p>
                </ModalBody>
                <ModalFooter>
                    <Form onSubmit={handleLogout}>
                        <Button color="danger" type='submit' onClick={logoutToggle}>{t('yes')}</Button>{' '}
                    </Form>
                </ModalFooter>
            </Modal>


            <header id="page-topbar" >

                <div className="layout-width d-flex justify-content-between align-items-center" style={{ backgroundColor: 'white', boxShadow: '0px 5px 10px rgba(209, 209, 209, 0.493)' }}>
                    <nav style={{ borderTop: '1px solid rgb(135 138 153 / 13%)'}}>
                        <div className="d-flex">
                            <button
                                onClick={toogleMenuBtn}
                                type="button"
                                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                                id="hamburger">
                                <span className="hamburger-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </button>
                        </div>
                        <ul className="navbar">
                            <div className="d-flex">
                                {
                                    (user.status === 'admin' || user.status === 'superadmin') ?
                                        <li className={active === 'dashboard' ? 'box active-box' : 'box'}
                                            data-aos='fade-right' onClick={(e) => activeLink(e)}>
                                            <Link to="/dashboard" className="navbar-link">
                                                <span className="align-items-center fs-15">
                                                    <i className='bx bx-home me-2'></i>
                                                    <span>{t('home')}</span>
                                                </span>
                                            </Link>
                                        </li> : ""
                                }

                                <li className={active === 'machine' ? 'box active-box' : 'box'} data-aos='fade-right'
                                    onClick={(e) => activeLink(e)}>
                                    <Link to="/machine" className="navbar-link">
                                        <span className="align-items-center fs-15">
                                            <i className='bx bx-server me-2'></i>
                                            <span>{t('machineList')}</span>
                                        </span>
                                    </Link>
                                </li>
                                <li className={active === 'order' ? 'box active-box' : 'box'} data-aos='fade-right'
                                    onClick={(e) => activeLink(e)}>
                                    <Link to="/order" className="navbar-link">
                                        <span className="align-items-center fs-15">
                                            <i className='bx bx-server me-2'></i>
                                            <span>{t('orderList')}</span>
                                        </span>
                                    </Link>
                                </li>
                                <li className={active === 'report' ? 'box active-box' : 'box'} data-aos='fade-right'
                                    onClick={(e) => activeLink(e)}>
                                    <Link to="/report" className="navbar-link">
                                        <span className="align-items-center fs-15">
                                            <i className='bx bx-server me-2'></i>
                                            <span>{t('reportPage')}</span>
                                        </span>
                                    </Link>
                                </li>
                                {
                                    user.status === 'admin' || user.status === 'superadmin' ?
                                        <li className={active === 'settings' ? 'box active-box' : 'box'}
                                            data-aos='fade-right' onClick={(e) => activeLink(e)}>
                                            <Link to="/settings" className="navbar-link">
                                                <span className="align-items-center fs-15">
                                                    <i className='bx bx-cog me-2'></i>
                                                    <span>{t('settings')}</span>
                                                </span>
                                            </Link>
                                        </li> : ""
                                }
                            </div>
                        </ul>
                    </nav>
                    <div className="d-flex align-items-center">
                                <Dropdown isOpen={search} toggle={toogleSearch} className="d-md-none topbar-head-dropdown header-item">
                                    <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                                        <i className="bx bx-search fs-22"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                                        <Form className="p-3">
                                            <div className="form-group m-0">
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="Search ..."
                                                        aria-label="Recipient's username" />
                                                    <button className="btn btn-primary" type="submit"><i
                                                        className="mdi mdi-magnify"></i></button>
                                                </div>
                                            </div>
                                        </Form>
                                    </DropdownMenu>
                                </Dropdown>
                                <LanguageDropdown t={t} />

                                {
                                    user.status === 'admin' || user.status === 'superadmin' ?
                                        <Notification t={t} /> :
                                        ""
                                }
                                <ProfileDropdown logoutToggle={logoutToggle} user={user} error={error} t={t} />
                            </div>
                </div>
            </header>

        </React.Fragment>
    );
};

export default Header;