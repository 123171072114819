import React, { useEffect, useMemo, useState } from 'react';
import TableContainer from '../../Components/Common/TableContainerReactTable';
import { Card, CardBody, CardHeader, Container, Button } from "reactstrap"



export const OperatorTable = (props) => {
    const { operators } = props;

    const searchTable = operators.map((item) => {
        const users = {
            "user_id": item.id,
            "username": item.username,
            "name": `${item.first_name} ${item.last_name}`
        }
        return users;
    });

    const columns = useMemo(
        () => [
            // {
            //     Header: 'Makina ID',
            //     accessor: 'machine_id',
            // },
            {
                Header: props.t('username'),
                accessor: 'username'
            },
            {
                Header: props.t('nameSurname'),
                accessor: 'name'
            },
            {
                Header: props.t('processes'),
                accessor: (cellProps) => {
                    return (
                        <Button size='sm' color='outline-primary'>
                            {props.t('edit')}
                        </Button>
                    )
                }
            }
        ],
        [props.t]
    );

    return (
        <Container fluid>
            <Card style={{ border: '1px solid #e9ebec', borderRadius: '5px', boxShadow: '0 3px 5px rgba(56,65,74,0.15)' }}>
                <CardHeader>
                    <h5 className='card-title text-center mb-0'>{props.t('operatorTable')}</h5>
                </CardHeader>
                <CardBody>

                    <TableContainer
                        columns={(columns || [])}
                        data={(searchTable.reverse() || [])}
                        isPagination={true}
                        flaw={true} // its just for web socket. After change flaw variable as websocket
                        isGlobalFilter={true}
                        iscustomPageSize={false}
                        isBordered={false}
                        customPageSize={5}
                        className="custom-header-css table align-middle table-nowrap"
                        tableClassName="table-centered align-middle table-nowrap mb-0"
                        theadClassName="text-muted table-light"
                        SearchPlaceholder={props.t('searchOperator')}
                        />
                </CardBody>
            </Card>
        </ Container>
);
}