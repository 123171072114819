import React, {useEffect, useState} from 'react'
import {Button} from "reactstrap";


const ReportTable = (props) => {
    const {searchTable, columns, pageSize, reportData, changePage, clearTable} = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(1);

    const handlePage = () => {
        // handle page count
        const page = Math.ceil(reportData && reportData.count / pageSize);
        setPage(page);
    }

    const handlePageChange = async (e, rotation) => {
        await changePage(e, rotation)
        if (rotation === 'next' && currentPage < page)
            setCurrentPage(currentPage + 1);
        else if (rotation === 'previous' && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    useEffect(() => {
        handlePage();
    }, [searchTable])

    useEffect(() => {
        if (clearTable)
            setCurrentPage(1);
    }, [clearTable])

     return (
         <div className="table-responsive table-card">
             <table className="table table-nowrap table-striped-columns mb-0 text-center" >
                 <thead className="table-dark">
                 <tr className="table-nowrap align-content-center text-center">
                     {
                         columns.map((column) => (
                             <th key={column.accessor} scope="col">{column.Header}</th>
                         ))
                     }
                 </tr>
                 </thead>
                 <tbody style={{ textAlign: "center", height: '200px' }}>
                 {
                     searchTable && searchTable.map((data, index) => (
                         <tr key={index}>
                             {
                                 columns.map((column) => (
                                     <td key={column.accessor}
                                         style={{ textAlign: "center", alignContent: "center", borderColor: 'rgba(128, 128, 128, 0.14)'}}>
                                         {column.Cell ? column.Cell({
                                             cell: {value: data[column.accessor]},
                                             row: {original: data}
                                         }) : data[column.accessor]}
                                     </td>
                                 ))
                             }
                         </tr>
                     ))
                 }
                 </tbody>
             </table>
             <div className='pagination-container'>
                 <ul className="pagination justify-content-center"
                     style={{
                         border: "1px solid rgba(128, 128, 128, 0.27)",
                         borderRadius: "3px",
                         width: "200px",
                            padding: "inherit",
                            margin: "2rem 1.3rem",

                     }}>
                     <li className="page-item">
                         <Button
                            className="page-link"
                            style={{borderRight: '1px solid rgba(128, 128, 128, 0.27)', borderRadius: '0'}}
                            onClick={(e) => handlePageChange(e, 'previous')}
                            disabled={reportData && reportData.previous ? false : true}>
                            {props.t('prev')}
                         </Button>
                     </li>
                     <li className="page-item">
                         <span className="page-link">
                             <b>{currentPage}</b> of <b>{page}</b>
                        </span>
                     </li>
                     <li className="page-item">
                         <Button
                            className="page-link"
                            style={{borderLeft: '1px solid rgba(128, 128, 128, 0.27)', borderRadius: '0'}}
                            onClick={(e) => handlePageChange(e, 'next')}
                            disabled={reportData && reportData.next ? false : true}
                         >{props.t('next')}
                         </Button>
                     </li>
                 </ul>
             </div>
         </div>
     );
};

export default ReportTable;