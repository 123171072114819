import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { Container } from "reactstrap";
import SidebarContent from "./SidebarContent";

const Sidebar = () => {
  const [active, setActive] = useState("");
  const location = useLocation();
  const navData = SidebarContent().props.children;

  // Sidebar Resize Logic
  const resizeSidebarMenu = useCallback(() => {
    var windowSize = document.documentElement.clientWidth;
    if (windowSize <= 795) {
      document.body.classList.remove("vertical-sidebar-enable");
      if (document.documentElement.getAttribute("data-layout") !== "horizontal") {
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
    }
    else if(windowSize > 795) {
        document.body.classList.remove("vertical-sidebar-enable");
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", resizeSidebarMenu, true);
    return () => {
      window.removeEventListener("resize", resizeSidebarMenu, true);
    };
  }, [resizeSidebarMenu]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const initMenu = () => {
      const ul = document.getElementById("navbar-nav");
      if (ul) {
        const items = ul.getElementsByClassName("nav-item");
        Array.from(items).forEach((item) => {
          if (item.children[0].pathname === location.pathname) {
            setActive(item.children[0].pathname);
          }
        });
      }
    };
    initMenu();
  }, [location.pathname]);

  useEffect(() => {
    var verticalOverlay = document.getElementsByClassName("vertical-overlay");
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener("click", function () {
        document.body.classList.remove("vertical-sidebar-enable");
        document.querySelector(".hamburger-icon").classList.toggle('open');
      });
    }
  });

  return (
    <React.Fragment>
      <div className="app-menu navbar-menu">
        <SimpleBar id="scrollbar" className="h-100">
          <Container fluid>
            <div id="two-column-menu"></div>
            <ul className="navbar-nav" id="navbar-nav">
              {(navData || []).map((item, key) => {
                return (
                  <React.Fragment key={key}>
                    {item.isHeader ? (
                      <li className="menu-title">
                        <span data-key="t-menu">{item.label} </span>
                      </li>
                    ) : (
                      <li
                        className={`nav-item ${
                          active === item.link ? "active" : ""
                        }`}
                      >
                        <Link
                          className="nav-link menu-link"
                          to={item.link || "/#"}
                        >
                          <i className={item.icon}></i>{" "}
                          <span>{item.label}</span>
                          {item.badgeName && (
                            <span
                              className={`badge badge-pill bg-${item.badgeColor}`}
                              data-key="t-new"
                            >
                              {item.badgeName}
                            </span>
                          )}
                        </Link>
                      </li>
                    )}
                  </React.Fragment>
                );
              })}
            </ul>
          </Container>
        </SimpleBar>
        <div className="sidebar-background"></div>
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  t: PropTypes.any,
};

export default Sidebar;