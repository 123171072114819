import React from 'react';
import ReactApexChart from "react-apexcharts";

const LineChart = (props) => {
  const { startDate, endDate, flaws } = props;
  
    // Generate entire date range
  const generateDateRange = (start, end) => {
    const dateArray = [];
    let currentDate = new Date(start);
    while (currentDate <= new Date(end)) {
      dateArray.push(new Date(currentDate).toISOString().split('T')[0]); // Add in "YYYY-MM-DD" format
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
  };

  const newStartDate = new Date(startDate);
  newStartDate.setUTCDate(newStartDate.getUTCDate() + 1);
  
  const newStartDatetoStr = newStartDate.toISOString();

  const allDates = generateDateRange(newStartDatetoStr, endDate);

    //Create normalized data based on existing dates
  const normalizedFlaws = allDates.map(date => {
    const existingFlaw = flaws?.find(flaw => flaw.date === date);
    return existingFlaw || { date, flaw_count: 0 };
  });

  const categories = normalizedFlaws.map(flaw => new Date(flaw.date).toLocaleDateString("tr-TR"));
  const flawCounts = normalizedFlaws.map(flaw => flaw.flaw_count);

  const DashedLineColors = ['#008FFB'];

  const series = [{
    name: "Hata Sayısı",
    data: flawCounts,
  }];

  const options = {
    chart: {
      height: 380,
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: DashedLineColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3],
      curve: 'straight',
      dashArray: [0],
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: categories,
      tickAmount: categories.length,
      title: {
        text: 'Tarih'
    },
    labels: {
        show: false
      },
    },
    yaxis:{
        title: {
            text: 'Hata Sayısı'
        }
    },
    tooltip: {
      y: [{
        title: {
          formatter: function (val) {
            return val;
          },
        },
      }],
    },
    grid: {
      borderColor: '#f1f1f1',
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        type="line"
        height="380" // Yüksekliği artırdık
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default LineChart;